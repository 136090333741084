import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useAuthContext } from 'components/shared/Auth';
import { DownOutlined, HeartOutlined, MessageOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import css from '../../ant/css/cards.module.css';
import { Avatar, Button, Col, Form, Row, Spin, Tooltip, Typography, Drawer } from 'antd';
import classNames from 'classnames';
import styles from '../../ant/css/stylesIndex.module.css';
import dynamic from 'next/dynamic';
import { ContentState, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Link from '../../shared/elements/Link';
import ZotzonAvatar from '../../shared/elements/images/ZotzonAvatar';
import { getRequest } from '../../../modules/request';

const { Paragraph } = Typography;

const Editor = dynamic(() => import('react-draft-wysiwyg').then((mod) => mod.Editor), { ssr: false });

export default function CardComment({ item, userData }) {
  const { t } = useTranslation('common');
  const { data, isAuth } = useAuthContext();
  const [messages, setMessages] = useState(false);
  const [allMessages, setAllMessages] = useState(false);
  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [lastComment, setLastComment] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const messageRef = useRef();
  const [users, setUsers] = useState([]);

  const onCloseComments = () => {
    setOpen(false);
  };
  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
  };

  useEffect(() => {
    getComments();
  }, []);

  const getUser = (id) => {
    getRequest()
      .get(`/users/${id}`)
      .then((res) => {
        return res.body;
      })
      .then((res) => {
        const list = users;
        list[id] = res;
        console.log('users', list);
        setUsers(list);
      });
  };

  const getComments = () => {
    fetch(`/api/comments/${item.id}`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMessages(data?.data.slice(0, 3));
        setAllMessages(data?.data);
        messageRef.current.scroll({
          top: 0,
          behavior: 'smooth',
        });
      })
      .catch((error) => {});
  };

  const onFinish = (values) => {
    putComment(values.message);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const putComment = (message) => {
    const messageText = message.blocks.reduce((acc, item) => {
      acc = acc + item.text;
      return acc;
    }, '');

    const emptyEditorState = EditorState.createWithContent(ContentState.createFromText(''));
    setEditorState(emptyEditorState);

    fetch(`/api/comments`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        listing_id: item.id,
        parent: null,
        comment: messageText,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLastComment(data?.id);
        setTimeout(() => {
          setLastComment(null);
        }, 2000);
        getComments();
      })
      .catch((error) => {});
  };

  return (
    <>
      <Row className={css.row}>
        <Col className={classNames(css.box, styles.cardColBox)}>
          <div className={classNames(css.bodyChat, styles.cardBodyChat)}>
            {!isAuth &&
              messages &&
              messages.map((item, index) => {
                if (users?.[item?.user?.id] === undefined) {
                  getUser(item?.user?.id);
                }

                if (index < 3 && !item?.t) {
                  return (
                    <div
                      key={item.id}
                      style={{
                        background: '#fff',
                        transition: 'background 1s',
                        margin: '0 8px',
                        padding: '2px 0',
                        textAlign: 'left',
                        borderRadius: '4px 4px 0 0 ',
                        borderBottom: '1px solid #f5f5f5',
                        color: '#333',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          flex: '0 0 7%',
                          padding: '10px 0 0 0',
                        }}
                      >
                        {users?.[item?.user?.id]?.avatarUrl && (
                          <ZotzonAvatar
                            style={{
                              borderRadius: '50%',
                              height: '30px',
                            }}
                            alt={data?.firstName}
                            src={userData?.avatarUrl}
                          />
                        )}
                        {!users?.[item?.user?.id]?.avatarUrl && <Avatar icon={<UserOutlined />} />}
                        {/*<UserOutlined />*/}
                      </div>
                      <div
                        style={{
                          padding: '0 0 6px 0',
                          flex: '0 0 86%',
                        }}
                      >
                        <div
                          style={{
                            padding: '6px 0',
                            fontSize: '10px',
                          }}
                        >
                          <div>
                            <span>{item?.user?.name}</span>
                            <span
                              style={{
                                padding: '0 6px',
                                color: '#bbb',
                              }}
                            >
                              {item?.created_at.substring(0, 16)}
                            </span>
                          </div>
                        </div>
                        {item?.message}
                      </div>
                      <div
                        style={{
                          flex: '0 0 7%',
                          padding: '10px 0 0 0',
                          textAlign: 'center',
                          color: '#aaa',
                        }}
                      >
                        <HeartOutlined />
                        {/*<div>0</div>*/}
                      </div>
                    </div>
                  );
                }
              })}
            {isAuth && (
              <div key='messages' ref={messageRef}>
                {messages &&
                  messages.map((item, index) => {
                    const itMy = item?.user?.id === data?.id;
                    return (
                      <>
                        <div
                          key={item.id}
                          style={{
                            background: lastComment === item.id ? '#dde7fa' : itMy ? '#eaf2fd' : '#fff',
                            transition: 'background 1s',
                            margin: itMy ? '2px 8px 2px 10%' : '0 8px',
                            padding: itMy ? '2px 10px 2px 10px' : '2px 0',
                            textAlign: itMy ? 'right' : 'left',
                            borderRadius: itMy ? '8px 4px 0 8px ' : '4px 4px 0 0 ',
                            borderBottom: itMy ? 'none' : '1px solid #f5f5f5',
                            color: '#333',
                            display: 'flex',
                          }}
                        >
                          <div
                            style={{
                              flex: '0 0 7%',
                              padding: '10px 0 0 0',
                            }}
                          >
                            {itMy ? false : <UserOutlined />}{' '}
                          </div>
                          <div
                            style={{
                              padding: '0 0 6px 0',
                              flex: '0 0 86%',
                            }}
                          >
                            <div
                              style={{
                                padding: '6px 0',
                                fontSize: '10px',
                              }}
                            >
                              <div>
                                {itMy ? false : <span>{item?.user?.name}</span>}
                                <span
                                  style={{
                                    padding: '0 6px',
                                    color: '#bbb',
                                  }}
                                >
                                  {item?.created_at.substring(0, 16)}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                maxWidth: '300px',
                              }}
                            >
                              <Paragraph
                                style={{
                                  padding: 0,
                                  margin: 0,
                                }}
                                ellipsis={{
                                  rows: 1,
                                  symbol: (
                                    <DownOutlined
                                      style={{
                                        color: '#888',
                                        fontSize: '10px',
                                      }}
                                    />
                                  ),
                                  expandable: true,
                                  // suffix: ' ...',
                                }}
                              >
                                {item?.message}
                              </Paragraph>
                            </div>
                          </div>
                          <div
                            style={{
                              flex: '0 0 7%',
                              padding: '10px 0 0 0',
                              textAlign: 'center',
                              color: '#aaa',
                            }}
                          >
                            <HeartOutlined />
                            {/*<div>0</div>*/}
                          </div>
                        </div>
                      </>
                    );
                  })}

                <div
                  style={{
                    textAlign: 'center',
                    padding: '10px 0',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {allMessages?.length == 0 ? (
                    t('be the first to comment')
                  ) : (
                    <span>
                      {t('show all')} {allMessages?.length} {t(' comments')}
                    </span>
                  )}
                </div>
              </div>
            )}
            <Drawer
              title='Comments'
              placement={'bottom'}
              width={500}
              height={'80%'}
              onClose={onCloseComments}
              open={open}
            >
              <div
                key='messages'
                ref={messageRef}
                style={{
                  maxHeight: '86%',
                  overflowY: 'scroll',
                }}
              >
                {allMessages &&
                  allMessages.map((item, index) => {
                    const itMy = item?.user?.id === data?.id;
                    return (
                      <>
                        <div
                          key={item.id}
                          style={{
                            background: lastComment === item.id ? '#dde7fa' : itMy ? '#eaf2fd' : '#fff',
                            transition: 'background 1s',
                            margin: itMy ? '2px 8px 2px 10%' : '0 8px',
                            padding: itMy ? '2px 10px 2px 10px' : '2px 0',
                            textAlign: itMy ? 'right' : 'left',
                            borderRadius: itMy ? '8px 4px 0 8px ' : '4px 4px 0 0 ',
                            borderBottom: itMy ? 'none' : '1px solid #f5f5f5',
                            color: '#333',
                            display: 'flex',
                          }}
                        >
                          <div
                            style={{
                              flex: '0 0 7%',
                              padding: '10px 0 0 0',
                            }}
                          >
                            {itMy ? false : <UserOutlined />}{' '}
                          </div>
                          <div
                            style={{
                              padding: '0 0 6px 0',
                              flex: '0 0 86%',
                            }}
                          >
                            <div
                              style={{
                                padding: '6px 0',
                                fontSize: '10px',
                              }}
                            >
                              <div>
                                {itMy ? false : <span>{item?.user?.name}</span>}
                                <span
                                  style={{
                                    padding: '0 6px',
                                    color: '#bbb',
                                  }}
                                >
                                  {item?.created_at.substring(0, 16)}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                maxWidth: '300px',
                              }}
                            >
                              <Paragraph
                                style={{
                                  padding: 0,
                                  margin: 0,
                                }}
                                ellipsis={{
                                  rows: 1,
                                  symbol: (
                                    <DownOutlined
                                      style={{
                                        color: '#888',
                                        fontSize: '10px',
                                      }}
                                    />
                                  ),
                                  expandable: true,
                                  // suffix: ' ...',
                                }}
                              >
                                {item?.message}
                              </Paragraph>
                            </div>
                          </div>
                          <div
                            style={{
                              flex: '0 0 7%',
                              padding: '10px 0 0 0',
                              textAlign: 'center',
                              color: '#aaa',
                            }}
                          >
                            <HeartOutlined />
                            {/*<div>0</div>*/}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              {/*{isAuth && !showChat && (*/}
              {/*  <div*/}
              {/*    style={{*/}
              {/*      textAlign: 'right',*/}
              {/*      padding: '0 20px 0 0',*/}
              {/*      cursor: 'pointer',*/}
              {/*    }}*/}
              {/*    onClick={() => setShowChat(true)}*/}
              {/*  >*/}
              {/*    <span*/}
              {/*      style={{*/}
              {/*        color: '#1890ff',*/}
              {/*        textDecoration: 'underline',*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <Tooltip title={t('add comment')}>*/}
              {/*        <MessageOutlined style={{ fontSize: '22px' }} />*/}
              {/*      </Tooltip>*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*)}*/}

              {isAuth && (
                <Form
                  name='basic'
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{
                    margin: '4px 4px 20px 4px',
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete='off'
                >
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', bottom: '8px', left: '2px' }}>
                      {userData?.avatarUrl && (
                        <ZotzonAvatar
                          style={{
                            borderRadius: '50%',
                            height: '30px',
                          }}
                          alt={data?.firstName}
                          src={userData?.avatarUrl}
                        />
                      )}
                    </div>
                    {!userData?.avatarUrl && <Avatar icon={<UserOutlined />} />}
                    <Form.Item
                      label={false}
                      name='message'
                      style={{
                        margin: '0px',
                        marginTop: '6px',
                        marginRight: '4px',
                        paddingLeft: '40px',
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your message!',
                        },
                      ]}
                    >
                      <Editor
                        editorStyle={{
                          border: '1px solid #ddd',
                          padding: '0px 0px',
                          borderRadius: '0 0 6px 6px',
                          // borderTop: 'none',
                          maxHeight: '200px',
                          // marginBottom: '10px',
                        }}
                        editorState={editorState}
                        toolbarClassName='toolbarClassName'
                        wrapperClassName='wrapperClassName'
                        editorClassName='editorClassName'
                        toolbarHidden={true}
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: [],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                    </Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{
                        position: 'absolute',
                        bottom: '14px',
                        right: '8px',
                        zIndex: '50',
                        width: '26px',
                        height: '26px',
                        borderRadius: '10px',
                        padding: '0 6px 0 6px',
                      }}
                    >
                      <SendOutlined style={{ fontSize: '14px' }} />
                    </Button>
                  </div>
                </Form>
              )}
            </Drawer>
            {!isAuth && !showChat && (
              <div
                style={{
                  textAlign: 'center',
                  padding: '10px 0',
                }}
              >
                <Link href='/auth/sign-in' className={classNames(styles.aLink, styles.aLinkMarginColor)}>
                  {t('log in to comment')}
                </Link>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
