import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'components/shared/elements/Link';
import { Row, Spin } from 'antd';
import css from '../../css/cards.module.css';
import styles from 'components/ant/css/stylesIndex.module.css';
import { listingsPath } from 'constants/path';
import { PER_PAGE } from 'constants/';
import { LeftOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import CardFooter from 'components/views/card/footer';
import CardTitle from 'components/views/card/title';
import CardGallery from 'components/views/card/gallery';
import CardHeader from 'components/views/card/header';
import classNames from 'classnames';
import 'swiper/css';
import CardComment from '../../../views/card/comment';
import { useAuthContext } from '../../../shared/Auth';
import { getRequest } from '../../../../modules/request';

const fetcher = (url, token) =>
  getRequest()
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .then((res) => res.body);

export const Cards = ({ listings, vip, page, slug, location, showPaginator = true, extrStyle = false }) => {
  const { t } = useTranslation('common');
  const { data, accessToken } = useAuthContext();
  const [userData, setUserData] = useState({});

  if (!listings?.entries || listings?.entries?.length < 24) {
    vip.entries = [];
  }

  const getUser = (id) => {
    getRequest()
      .get(`/users/${id}`)
      .then((res) => {
        return res.body;
      })
      .then((res) => {
        setUserData(res);
      });
  };

  useEffect(() => {
    if (data?.id) {
      getUser(data?.id);
    }
  }, [data]);

  let itemIndex = 0;
  let freeItems = listings?.entries?.slice((vip?.entries.length ?? 0) * 6);

  const [targetReached, setTargetReached] = useState(undefined);

  const updateTarget = useCallback(
    (e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    },
    [setTargetReached],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const media = window.matchMedia(`(max-width: ${600}px)`);
      media.addEventListener('change', updateTarget);
      setTargetReached(media.matches);
      return () => media.removeEventListener('change', updateTarget);
    }
  }, []);

  const paginator = !showPaginator ? (
    ''
  ) : (
    <div className={styles.cardsDiv}>
      <Link
        className={styles.cardsLink}
        href={listingsPath({ slug: slug, location: location, page: parseInt(page) - 1 })}
      >
        <LeftOutlined />
      </Link>
      {(parseInt(page) - 1) * PER_PAGE + 1} -{' '}
      {listings?.count > parseInt(page) * PER_PAGE ? parseInt(page) * PER_PAGE : listings?.count}
      <span className={styles.cardsSpan}>{t('from')} </span>
      {listings?.count}
      <Link
        className={styles.cardsListingLink}
        href={listingsPath({ slug: slug, location: location, page: parseInt(page) + 1 })}
      >
        <RightOutlined />
      </Link>
    </div>
  );

  return (
    <>
      {targetReached === undefined && (
        <div
          style={{
            height: '50px',
            textAlign: 'center',
            margin: '100px auto',
          }}
        >
          <Spin />
        </div>
      )}
      {targetReached === true && (
        <div
          style={{
            margin: '60px 0 0 0',
          }}
        >
          <div style={{ height: '88vh' }}>
            {listings?.entries.map((item, key) => {
              const img = item?.covers?.length > 0 ? item?.covers[0] : {};
              const orientation = img?.height ? img?.height / img?.width : 1;
              return (
                <div key={`card${item.id}`}>
                  <div
                    className={css.card}
                    style={{
                      minHeight: orientation >= 1 ? '50vh' : '50vh',
                    }}
                  >
                    <CardHeader item={item} />
                    <div className={css.cardImg}>
                      <CardGallery item={item} orientation={orientation} />
                    </div>
                    <div className={css.cardFooter}>
                      <CardTitle item={item} />
                      <div className={css.cardFooterBtn}>
                        <CardFooter item={item} />
                      </div>
                    </div>
                    <div
                      style={{
                        maxHeight: '400px',
                      }}
                    >
                      <CardComment item={item} userData={userData} />
                    </div>
                  </div>
                </div>
              );
            })}
            <div style={{ height: '110px', padding: '30px 0 0 0' }}>{paginator}</div>
          </div>
        </div>
      )}

      {targetReached === false && (
        <Row className={css.aRow} style={extrStyle ? extrStyle : {}}>
          {Array.isArray(vip?.entries) &&
            vip?.entries?.map((vipItem, key) => {
              let subItems = listings?.entries?.slice(itemIndex * 6, (itemIndex + 1) * 6);
              itemIndex++;
              return (
                <Row
                  key={`viprow${vipItem.id}`}
                  className={key % 2 === 0 ? css.row : classNames(css.row, css.rowR, styles.cardsRow)}
                >
                  {key % 2 === 0 && (
                    <div className={css.cardVip} key={`vipCard${vipItem.id}`}>
                      <CardHeader item={vipItem} isVip />
                      <div className={css.cardImg}>
                        <CardGallery item={vipItem} isVip />
                      </div>
                      <div className={css.cardFooter}>
                        <CardTitle item={vipItem} isVip />
                        <div className={css.cardVipFooter}>
                          <CardFooter item={vipItem} />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={css.subVipRow}>
                    {subItems?.map((item, key) => {
                      return (
                        <div className={css.card} key={`card${item.id}`}>
                          <CardHeader item={item} />

                          <div
                            className={css.cardImg}
                            style={
                              targetReached
                                ? { maxHeight: item?.videos && item?.videos.length > 0 ? '700px' : '400px' }
                                : {}
                            }
                          >
                            <CardGallery item={item} />
                          </div>

                          <div className={css.cardFooter}>
                            <CardTitle item={item} />
                            <div className={css.cardFooterBtn}>
                              <CardFooter item={item} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {key % 2 !== 0 && (
                    <div className={css.cardVip} key={`vipCard${vipItem.id}`}>
                      <CardHeader item={vipItem} isVip />
                      <div className={css.cardImg}>
                        <CardGallery item={vipItem} isVip />
                      </div>
                      <div className={css.cardFooter}>
                        <CardTitle item={vipItem} isVip />
                        <div className={css.cardVipFooter}>
                          <CardFooter item={vipItem} />
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              );
            })}
          {freeItems?.map((item, key) => {
            return (
              <div className={css.card} key={`card${item.id}`}>
                <CardHeader item={item} />
                <div className={css.cardImg}>
                  <CardGallery item={item} />
                </div>
                <div className={css.cardFooter}>
                  <CardTitle item={item} />
                  <div className={css.cardFooterBtn}>
                    <CardFooter item={item} />
                  </div>
                </div>
              </div>
            );
          })}
          {paginator}
        </Row>
      )}
    </>
  );
};
